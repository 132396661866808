const container = document.getElementById('domains-data')
const statuses  = ['focused2009', 'focused2015', 'focused2019', 'focusedNext']
let current = 0
let interval, triggers

if (container) triggers = container.querySelectorAll('.dates > div')
if (triggers && triggers.length) {
  interval = setInterval(increment, 3000)
  Array.prototype.forEach.call(triggers, addTrigger)
}

function increment () {
  update(current + 1)
}

function update (index) {
  if (index > statuses.length - 1) index = 0

  container.classList.remove(statuses[current])
  container.classList.add(statuses[index])
  current = index
}

function addTrigger (element, index) {
  element.addEventListener('click', function () {
    if (interval) clearInterval(interval)
    update(index)
  })
}
