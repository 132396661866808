angular.module('monTerritoire', [
  'public'
])

require('mon-territoire/features/carousel.js')
require('mon-territoire/features/domains.js')
require('mon-territoire/features/popin.js')
require('mon-territoire/features/slides.js')
require('mon-territoire/features/team.js')
require('mon-territoire/features/tweets.js')
