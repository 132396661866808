angular
  .module('public')
  .controller('CarouselController', CarouselController)

CarouselController['$inject'] = ['$element']
function CarouselController ($element) {
  const ctrl     = this
  const articles = $element.find('article')

  ctrl.currentIndex = 0
  ctrl.showPrevious = showPrevious
  ctrl.showNext     = showNext

  function showPrevious () {
    let index = ctrl.currentIndex - 1
    if (index < 0) index = articles.length - 1

    ctrl.currentIndex = index
  }

  function showNext () {
    let index = ctrl.currentIndex + 1
    if (index >= articles.length) index = 0

    ctrl.currentIndex = index
  }
}
