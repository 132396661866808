angular
  .module('public')
  .run(popin)

popin['$inject'] = ['$templateRequest', '$compile', '$rootScope']
function popin ($templateRequest, $compile, $rootScope) {
  let scope, template, content, overflow, timeout
  const ctrl = {}

  initialize()

  ctrl.close             = close
  $rootScope.cancelPopin = cancel

  function initialize () {
    if (!document.getElementById('popin.html')) return

    delayOpening()

    window.addEventListener('mousemove', delayOpening)
    window.addEventListener('scroll', delayOpening)
    window.addEventListener('click', delayOpening)
    window.addEventListener('keydown', delayOpening)
  }

  function cancel () {
    if (timeout) clearTimeout(timeout)

    window.removeEventListener('mousemove', delayOpening)
    window.removeEventListener('scroll', delayOpening)
    window.removeEventListener('click', delayOpening)
    window.removeEventListener('keydown', delayOpening)
  }

  function delayOpening () {
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(open, 10000)
  }

  function open () {
    cancel()

    $templateRequest('popin.html')
      .then(function (html) {
        scope       = $rootScope.$new(true)
        scope.$ctrl = ctrl
        template    = angular.element(html)
        content     = angular.element('<div class="popin-content">').append($compile(template)(scope))
        overflow    = angular.element('<div class="popin-overflow">')
        overflow.on('click', close)

        angular.element(document.body)
          .append(overflow)
          .append(content)
      })
      .catch(angular.noop)
  }

  function close () {
    overflow.remove()
    content.remove()
  }
}
