const applicants = document.querySelectorAll('.applicant')

if (applicants.length) {
  setInterval(move, 2000, '.functions div')
}

function move (selector) {
  applicants.forEach(function (applicant) {
    const current = applicant.querySelector(selector + '.current')
    const others  = applicant.querySelectorAll(selector + ':not(.current)')

    const choice  = others[Math.floor(Math.random() * others.length)]

    if (!current && !choice) return

    current.classList.add('remove-current')
    choice.classList.add('add-current')

    setTimeout(function () {
      current.classList.remove('current')
      current.classList.remove('remove-current')
      choice.classList.add('current')
      choice.classList.remove('add-current')
    }, 1000)
  })
}
