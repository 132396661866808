angular
  .module('public')
  .component('tweets', {
    controller: TweetsController,
    template:   require('./tweets.template.pug')()
  })

TweetsController['$inject'] = ['$window', '$http']
function TweetsController ($window, $http) {
  const ctrl = this

  ctrl.$onInit = onInit

  function onInit () {
    $http.get('/twitter').then(function (response) {
      ctrl.tweets = parseTweets(response.data)
    })
  }

  // Public Functions
  // -------------------------------------------------------------------------
  function parseTweets (tweets) {
    tweets.forEach(function (tweet) {
      tweet.text = tweet.text.replace(/((http|https|ftp):\/\/[\w?=&./-;#~%-]+(?![\w\s?&./;#~%"=-]*>))/g, '<a target="_blank" href="$1">$1</a> ')
      tweet.text = tweet.text.replace(/(@[\w]+)/g, '<a target="_blank" href="https://twitter.com/$1">$1</a> ')
      tweet.text = tweet.text.replace(/(#[\wÀ-ÿ]+)/g, '<a target="_blank" href="https://twitter.com/hashtag/$1">$1</a> ')
    })

    return tweets
  }
}
