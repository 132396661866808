const slides = document.querySelectorAll('slide')
let current  = 0

if (slides.length) {
  setInterval(increment, 3000)
}

function increment () {
  let index = current + 1
  if (index > slides.length - 1) index = 0

  slides[current].classList.remove('active')
  slides[index].classList.add('active')
  current = index
}
